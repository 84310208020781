<script lang="ts">
  // your script goes here

  import Modal from '$lib/components/Modal.svelte';
  import { closeModal, openModal } from '$lib/stores/modals.store';
  import type { ClientUserStore } from 'shared/src';
  import { page } from '$app/stores';
  import type { AuthFlowParameters } from 'shared/definitions/auth-object-definitions';
  import LoginForm from '$lib/app/authentication/LoginForm.svelte';
  import { createEventDispatcher } from 'svelte';
  import type { UserWithJoins } from 'shared/definitions/user-object-definitions';
  import Logo from '$lib/components/Logo.svelte';
  import Hr from '$lib/components/Hr.svelte';
  export let clientUserStore: ClientUserStore | undefined = undefined;
  export let parameters: AuthFlowParameters = {};
  export let doNotCloseModal = false;

  const modalId = 'login';
  const dispatch = createEventDispatcher<{ loginComplete: UserWithJoins; close: null }>();

  $: isInApp = $page.url.pathname.includes('app/');

  function openGuard() {
    if (clientUserStore && clientUserStore.type !== 'anonymous') {
      setTimeout(closeModal, 300);
    }
  }
</script>

<Modal {modalId} class="max-w-2xl" on:open={openGuard} on:close={() => dispatch('close')}>
  <div slot="title" class="text-gray-300 high-contrast:text-gray font-thin text-lg my-4">
    {#if isInApp}
      Sign in to save your progress
    {:else}
      <div class="text-center">
        <Logo class="text-cyan-600 h-10 sm:h-16 mx-auto" />
        Sign in to your account
      </div>
    {/if}
  </div>
  <Hr class="mb-3" />
  <LoginForm
    {clientUserStore}
    postSocialLoginRedirectUrl={$page.url.pathname}
    on:loginComplete={(evt) => {
      if (!doNotCloseModal) closeModal();
      dispatch('loginComplete', evt.detail);
    }}
    on:register={() => {
      openModal('register');
    }}
    on:forgottenPassword={() => {
      openModal('passwordReset');
    }}
    {parameters}
  />
</Modal>
