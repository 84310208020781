<script lang="ts">
  // your script goes here

  import Modal from '$lib/components/Modal.svelte';
  import type { ClientUserStore } from 'shared/src';
  import Logo from '$lib/components/Logo.svelte';
  import Button from '$lib/components/Button.svelte';
  import { slide } from 'svelte/transition';
  import { closeModal, openModal } from '$lib/stores/modals.store';
  import type { AuthFlowParameters } from 'shared/definitions/auth-object-definitions';
  import RegistrationForm from '$lib/app/authentication/RegistrationForm.svelte';
  import { createEventDispatcher } from 'svelte';
  import type { UserWithJoins } from 'shared/definitions/user-object-definitions';

  export let clientUserStore: ClientUserStore | undefined = undefined;

  export let parameters: AuthFlowParameters = {};

  export let accountType: 'student' | 'teacher' | 'unknown' = 'unknown';

  export let doNotCloseModal = false;

  const modalId = 'register';

  let state: 'choosingUserRole' | 'registering' | 'done' =
    accountType === 'unknown' ? 'choosingUserRole' : 'registering';

  let registrationForm: RegistrationForm;

  const dispatch = createEventDispatcher<{ registrationComplete: UserWithJoins; close: null }>();

  function setRole(role: 'student' | 'teacher') {
    accountType = role;
    state = 'registering';
  }

  function cleanup() {
    state = 'choosingUserRole';
    accountType = 'unknown';
    registrationForm?.cleanup();
  }

  function openGuard() {
    if (clientUserStore && clientUserStore.type !== 'anonymous') {
      setTimeout(closeModal, 300);
    }
  }
</script>

<Modal
  {modalId}
  class="max-w-2xl"
  on:close={() => {
    setTimeout(cleanup, 500);
    dispatch('close');
  }}
  on:open={openGuard}
>
  <div
    slot="title"
    class="text-gray-300 high-contrast:text-gray font-thin text-1xl xs:text-2xl pb-3 border-b border-gray-100"
  >
    Register for <span class="text-cyan-600"
      ><Logo class="h-6 xs:h-8 inline-block -translate-y-0.5" /></span
    >
  </div>
  {#if state === 'choosingUserRole'}
    <div out:slide|local class="text-center text-gray-300 my-4 text-lg">
      <p>Tell us who you are.</p>

      <div class="flex justify-around max-w-lg mx-auto p-4 space-x-8">
        <Button class="flex-grow" on:click={() => setRole('student')}>I am a student.</Button>
        <Button class="flex-grow" on:click={() => setRole('teacher')}>I am a teacher.</Button>
      </div>
    </div>
  {:else if state === 'registering'}
    <RegistrationForm
      {doNotCloseModal}
      isTeacher={accountType === 'teacher'}
      {clientUserStore}
      bind:this={registrationForm}
      {parameters}
      on:registrationComplete={(evt) => {
        if (!doNotCloseModal) {
          closeModal();
        }
        dispatch('registrationComplete', evt.detail);
      }}
    />
  {/if}
  <div class="text-center text-gray-300 high-contrast:text-gray font-thin text-lg pt-1">
    Already have an account? <button
      class="text-blue-light"
      on:click={() => {
        openModal('login');
      }}>Sign in.</button
    >
  </div>
</Modal>
